import React, { useEffect, useState } from "react";
import stock from "../IMAGES/shophq-blue.png";
import { useNavigate, useParams } from "react-router-dom";
import { firebase_GetAllKeys, shopify_GetProduct } from "../FIREBASE/firebase";
import PageNavigation from "../UTILITIES/PageNavigation";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import { Spacer } from "../COMPONENT/Components";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { FaApple } from "react-icons/fa";
import { FaGooglePlay } from "react-icons/fa";
import { MdDesktopWindows } from "react-icons/md";
import Footer from "../UTILITIES/Footer";
import { Helmet } from "react-helmet";

export default function Product() {
  const { name, collectionID, productID } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState({});
  const [imageNum, setImageNum] = useState(0);
  const [variants, setVariants] = useState([]);
  const [vendorURL, setVendorURL] = useState("");
  const [apple, setApple] = useState("");
  const [google, setGoogle] = useState("");
  const [category, setCategory] = useState("");
  const [vendorName, setVendorName] = useState("");

  useEffect(() => {
    firebase_GetAllKeys((keys) => {
      const thisOne = keys.find(
        (ting) => ting.Name === name.replaceAll("-", " ")
      );
      setCategory(thisOne.Category);
      setVendorURL(thisOne.URL);
      setApple(thisOne.Apple);
      setGoogle(thisOne.Google);
      setVendorName(thisOne.Name);
      shopify_GetProduct(
        `gid://shopify/Product/${productID}`,
        thisOne.StorefrontAPI,
        thisOne.URL,
        (prod) => {
          console.log(prod);
          setProduct(prod);
          if (prod.variants !== null) {
            setVariants(prod.variants);
          }
        }
      );
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="poppins">
      {/* HELMET */}
      <Helmet>
        <title>
          {`${product.title}`} - {name.replaceAll("-", " ")} | Shopfolio
          Shopping Experience
        </title>
        <meta
          name="description"
          content="Discover the best from handpicked Shopify vendors at Shopfolio. Our curated catalog offers top-quality products and a seamless shopping experience. Support small businesses and independent artisans while enjoying fashion, home goods, accessories, and more. Shop now for the best in quality and style!"
        />
        <meta name="robots" content="index, follow" />
        <meta
          name="keywords"
          content="shopping,Shopify,products,market,pop! market,clothing,toys,home,stationery,technology"
        />
        <meta http-equiv="content-language" content="en-us" />
        <meta name="author" content="Innovative Internet Creations" />
        <link rel="canonical" href="https://www.popmarketonline.com" />
      </Helmet>
      <PageNavigation />
      <div className="body">
        {/* BACK */}
        <div className="separate">
          <div
            className="side padding_h"
            onClick={() => {
              navigate(`/${name}/${collectionID}/`);
            }}
          >
            <FaArrowLeftLong />
            <p className="no pointer">collection</p>
          </div>
          <div
            className="side padding_h pointer"
            onClick={() => {
              navigate(`/products/${category}`);
            }}
          >
            <p className="no">{category}</p>
            <FaArrowRightLong />
          </div>
        </div>
        <Spacer height={15} />
        <div className="product-detail-wrap">
          <div className="product-img">
            <img
              src={
                product.images !== undefined &&
                product.images !== null &&
                product.images.length > 0
                  ? product.images[imageNum]
                  : stock
              }
              alt={product.title}
            />
            {product.images !== undefined &&
            product.images !== null &&
            product.images.length > 0 ? (
              <div className="product-arrows-wrap">
                <div></div>
                <div className="product-arrows">
                  <div
                    className="image-circle pointer"
                    onClick={() => {
                      setImageNum((prev) =>
                        prev === 0 ? product.images.length - 1 : prev - 1
                      );
                    }}
                  >
                    <FaChevronLeft />
                  </div>
                  <div
                    className="image-circle pointer"
                    onClick={() => {
                      setImageNum((prev) =>
                        prev === product.images.length - 1 ? 0 : prev + 1
                      );
                    }}
                  >
                    <FaChevronRight />
                  </div>
                </div>
                <div></div>
              </div>
            ) : (
              <div></div>
            )}
            <div></div>
          </div>
          <div className="product-details">
            <p
              onClick={() => {
                window.open(`https://${vendorURL}`, "_blank");
              }}
              className="no pointer underline"
              style={{
                fontSize: "30px",
                color: "#28D782",
                fontWeight: 500,
                fontVariant: "all-small-caps",
              }}
            >
              {vendorName}
            </p>
            <h1 className="no">{product.title}</h1>
            <div className="product-variants">
              {variants.length > 0 && variants[0].title === "Default Title" && (
                <div>
                  <p className="no product-price">
                    ${parseFloat(variants[0].price).toFixed(2)}
                  </p>
                </div>
              )}
              {variants.length > 0 && variants[0].title !== "Default Title" && (
                <div>
                  {/* <Spacer height={20} /> */}
                  {/* <h4 className="product-option-title no">Options</h4> */}
                  {variants.map((variant, i) => {
                    return (
                      <div key={i} className="product-option">
                        <p className="no">{variant.title}</p>
                        <p className="no">
                          ${parseFloat(variant.price).toFixed(2)}
                        </p>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div className="">
              <p className="no product-desc">{product.description}</p>
            </div>
          </div>
        </div>
        <Spacer height={100} />
        {/* LINKS */}
        <div className="product-bottom">
          <p className="no">
            To purchase, download their app or visit their site:
          </p>
          <Spacer height={4} />
          <div className="button-options">
            {apple !== "" && apple !== undefined && (
              <div
                className="button-option apple pointer"
                onClick={() => {
                  window.open(apple, "blank");
                }}
              >
                <FaApple className="button-option-icon" />
              </div>
            )}
            {google !== "" && google !== undefined && (
              <div
                className="button-option play pointer"
                onClick={() => {
                  window.open(google, "blank");
                }}
              >
                <FaGooglePlay className="button-option-icon" />
              </div>
            )}
            <div
              className="button-option desktop pointer"
              onClick={() => {
                window.open(
                  `https://${vendorURL}/products/${product.handle
                    .replaceAll(" ", "-")
                    .replaceAll("/", "-")
                    .toLowerCase()}`,
                  "blank"
                );
              }}
            >
              website
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
