import React, { useEffect } from "react";
import PageNavigation from "../UTILITIES/PageNavigation";
import Footer from "../UTILITIES/Footer";
import { Spacer } from "../COMPONENT/Components";
import { GoNorthStar } from "react-icons/go";
import { PiPlanetBold } from "react-icons/pi";

import img1 from "../IMAGES/shophq-about1.jpg";
import img2 from "../IMAGES/shophq-about2.jpg";
import img3 from "../IMAGES/shophq-about3.jpg";
import img4 from "../IMAGES/shophq-about4.jpg";
import { Helmet } from "react-helmet";

export default function About() {
  useEffect(() => {
    // document.title = 'About Shopfolio | Shopfolio Shopping Experience';
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="poppins">
      {/* HELMET */}
      <Helmet>
        <title>About Shopfolio | Shopfolio Shopping Experience</title>
        <meta
          name="description"
          content="Discover the best from handpicked Shopify vendors at Shopfolio. Our curated catalog offers top-quality products and a seamless shopping experience. Support small businesses and independent artisans while enjoying fashion, home goods, accessories, and more. Shop now for the best in quality and style!"
        />
        <meta name="robots" content="index, follow" />
        <meta
          name="keywords"
          content="shopping,Shopify,products,market,pop! market,clothing,toys,home,stationery,technology"
        />
        <meta http-equiv="content-language" content="en-us" />
        <meta name="author" content="Innovative Internet Creations" />
        <link rel="canonical" href="https://www.popmarketonline.com" />
      </Helmet>
      {/*  */}
      <PageNavigation />
      <div className="body">
        {/* TOP */}
        <div className="about-split">
          <div>
            <div className="about-head side">
              <h1 className="no">/ About Shopfolio</h1>
              <PiPlanetBold className="about-head-icon" />
            </div>
            <h2 className="about-top">
              In the realm of e-commerce, the market has become inundated with
              an overwhelming array of inexpensive products and subpar service
              experiences. At{" "}
              <span className="about-blue">
                <b>Shopfolio</b>
              </span>
              , we're revolutionizing the industry by meticulously curating our
              vendor selection. Our hand-picked vendors undergo a rigorous
              selection process to ensure that only the finest products make it
              to our platform. By doing so, we're not just offering products;
              we're creating a superior shopping experience where users can
              confidently purchase items they're guaranteed to love. Discover
              the difference at Shopfolio, where quality and service reign
              supreme.
            </h2>
            <Spacer height={15} />
          </div>
          <div className="about-wrap">
            <div className="padding about-block">
              <div className="side">
                <GoNorthStar className="about-star" />
                <h3 className="no">Quality Catalog</h3>
              </div>
              <p className="no">
                Explore the <span className="about-blue">Shopfolio</span>{" "}
                Catalog, where every item is sourced from handpicked Shopify
                vendors renowned for their commitment to quality and trendiness.
                Discover an exclusive selection of products curated with care.
                Once you've found the perfect item, enjoy a seamless purchasing
                experience through our user-friendly app or website. Elevate
                your shopping journey with{" "}
                <span className="about-blue">Shopfolio</span>, where
                excellence meets convenience.
              </p>
            </div>
            <div className="padding about-block">
              <div className="side">
                <GoNorthStar className="about-star" />
                <h3 className="no">Vendor Apps</h3>
              </div>

              <p className="no">
                Experience the ultimate in personalized shopping with{" "}
                <span className="about-blue">Shopfolio</span>'s innovative
                approach. Our Shopify vendors have their own individual apps,
                enhancing your shopping journey with a tailored touch. This
                unique feature not only provides you with a more personalized
                experience but also allows vendors to showcase their brand with
                their own logo and name. This adds a layer of credibility and
                trust, ensuring you can shop with confidence. Discover the
                future of online shopping with{" "}
                <span className="about-blue">Shopfolio</span>.
              </p>
            </div>
            <div className="padding about-block">
              <div className="side">
                <GoNorthStar className="about-star" />
                <h3 className="no">Shopify Pipeline</h3>
              </div>

              <p className="no">
                Unlock the potential of your Shopify store with{" "}
                <span className="about-blue">Shopfolio</span>'s cutting-edge
                solution. You can now have your own app, developed by our team,
                seamlessly integrated with your Shopify inventory. This means
                that all your products will not only be featured on the{" "}
                <span className="about-blue">Shopfolio</span> website and app
                catalogs but also on your own branded app. Plus, any
                modifications you make to your Shopify inventory will be
                instantly updated across all platforms. Experience the
                convenience and efficiency of unified inventory management with{" "}
                <span className="about-blue">Shopfolio</span>.
              </p>
            </div>
          </div>
        </div>

        <div className="about-images">
          <img src={img1} />
          <img src={img2} />
          <img src={img3} />
          <img src={img4} />
        </div>
      </div>

      <Footer />
    </div>
  );
}
