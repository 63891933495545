import React, { useEffect } from "react";
import PageNavigation from "../UTILITIES/PageNavigation";
import Footer from "../UTILITIES/Footer";
import { Spacer } from "../COMPONENT/Components";
import { MdArrowOutward } from "react-icons/md";
import img1 from "../IMAGES/shophq-online.jpg";
import { IoStorefrontOutline } from "react-icons/io5";
import emailjs from "@emailjs/browser";
import { Helmet } from "react-helmet";

export default function Partners() {
  function onSendContactForm() {
    const businessName = document.querySelector("#tbBusinessName").value;
    const contactName = document.querySelector("#tbContactName").value;
    const email = document.querySelector("#tbEmail").value;
    const phone = document.querySelector("#tbPhone").value;

    emailjs.init({
      publicKey: "L6fRNP99J11mfa0UD",
    });
    emailjs
      .send("bagel", "contact_customer", {
        toEmail: "bagel@shopfolio.us",
        fromName: contactName,
        fromEmail: email,
        replyTo: email,
        subject: `Shopfolio Partner Request`,
        HTML: `
        <div>
          <h1 style="padding: 0; margin: 0">Business Name: ${businessName}</h1><br/>
          <h1 style="padding: 0; margin: 0">Contact Name: ${contactName}</h1><br/>
          <h1 style="padding: 0; margin: 0">Email: ${email}</h1><br/>
          <h1 style="padding: 0; margin: 0">Phone: ${phone}</h1>
        </div>
      `,
      })
      .then(
        (response) => {
          document.querySelector("#tbBusinessName").value = "";
          document.querySelector("#tbContactName").value = "";
          document.querySelector("#tbEmail").value = "";
          document.querySelector("#tbPhone").value = "";

          alert("Thank you! We will send you an email shortly.");

          console.log("SUCCESS!", response.status, response.text);
        },
        (error) => {
          console.log("FAILED...", error);
        }
      );
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="poppins">
      {/* HELMET */}
      <Helmet>
        <title>Partners | Shopfolio Shopping Experience</title>
        <meta
          name="description"
          content="Discover the best from handpicked Shopify vendors at Shopfolio. Our curated catalog offers top-quality products and a seamless shopping experience. Support small businesses and independent artisans while enjoying fashion, home goods, accessories, and more. Shop now for the best in quality and style!"
        />
        <meta name="robots" content="index, follow" />
        <meta
          name="keywords"
          content="shopping,Shopify,products,market,pop! market,clothing,toys,home,stationery,technology"
        />
        <meta http-equiv="content-language" content="en-us" />
        <meta name="author" content="Innovative Internet Creations" />
        <link rel="canonical" href="https://www.popmarketonline.com" />
      </Helmet>
      <PageNavigation />
      <div className="body partners-main-wrap">
        <div className="left">
          <div className="padding">
            <div className="partners-head">
              <h1 className="no">
                / Become a Shopfolio Partner.{" "}
                <IoStorefrontOutline className="about-blue" />
              </h1>
            </div>
            <div className="partners-top">
              <p>
                Becoming a Shopfolio Partner offers your business a valuable
                opportunity for growth and success. By joining Shopfolio, you
                can tap into a community that is passionate about discovering
                top-tier products. This partnership can help elevate your brand,
                expanding your reach and connecting you with a wider audience.
                <br />
                <br />
                <b className="thin">
                  As a Shopfolio Partner, a subscription of{" "}
                  <span className="blue">$20</span> a month is required. This
                  subscription includes your very own{" "}
                  <span className="green">Android</span> and
                  <span className="gray"> iPhone</span> app, ensuring your store
                  and products are easily accessible to a broader audience. All
                  products and your store will be prominently displayed on the
                  Shopfolio website, complete with redirect links to your own
                  website. This setup helps increase traffic and sales.
                </b>
                <br />
                <br />
                <b className="thin">
                  Additionally, your products will be indexed for double the
                  search power, making it easier for potential customers to find
                  what they're looking for. This increased visibility can be a
                  game-changer for your business.
                </b>
                <br />
                <br />
                One of the key benefits of partnering with Shopfolio is the
                potential to significantly increase your sales. By showcasing
                your products on their platform, you can attract new customers
                and drive more traffic to your online store. Additionally, being
                a Shopfolio Partner can enhance your brand's reputation and
                credibility, as you will be associated with a trusted and
                respected platform.
                <br />
                <br />
                Furthermore, as a Shopfolio Partner, you may have access to
                exclusive marketing opportunities. Shopfolio often promotes its
                partners' products through various marketing channels, further
                increasing your visibility and attracting more customers to your
                brand.
                <br />
                <br />
                Overall, becoming a Shopfolio Partner can be a game-changer for
                your business. It can open up new opportunities for growth,
                increase your sales, and help you build a stronger, more
                recognizable brand in the market.
              </p>
            </div>
          </div>
          <Spacer height={40} />
        </div>
        <div className="right">
          <div className="partners-form-head">
            <h2 className="no"> Submit a request to be Shopfolio Partner</h2>
          </div>
          <Spacer height={25} />
          <div className="partners-form-wrap">
            <div className="partners-form-block">
              <p className="no">Business Name</p>
              <input
                type="text"
                className="partners-tb"
                id="tbBusinessName"
                placeholder="ex. Bagelocity LLC"
              />
            </div>
            <div className="partners-form-block">
              <p className="no">Contact Name</p>
              <input
                type="text"
                className="partners-tb"
                id="tbContactName"
                placeholder="ex. Joe Shmoe"
              />
            </div>
            <div className="partners-form-block">
              <p className="no">Email</p>
              <input
                type="text"
                className="partners-tb"
                id="tbEmail"
                placeholder="ex. info@bagelocity.com"
              />
            </div>
            <div className="partners-form-block">
              <p className="no">Phone</p>
              <input
                type="text"
                className="partners-tb"
                id="tbPhone"
                placeholder="ex. 123-456-7890"
              />
            </div>
            <div
              className="partners-btn separate pointer"
              onClick={() => {
                onSendContactForm();
              }}
            >
              <div></div>
              <div className="side">
                <p>Submit</p>
                <MdArrowOutward className="partners-btn-icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="partners-img">
        <img src={img1} />
      </div>
      <Footer />
    </div>
  );
}
