import React, { useEffect, useState } from "react";
import PageNavigation from "../UTILITIES/PageNavigation";
import { firebase_GetAllKeys } from "../FIREBASE/firebase";
import { removeDuplicates, sortArray } from "../Global";
import { MdArrowOutward } from "react-icons/md";
import { Spacer } from "../COMPONENT/Components";
import { useNavigate } from "react-router-dom";
import Footer from "../UTILITIES/Footer";
import { Helmet } from "react-helmet";

export default function Categories() {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    firebase_GetAllKeys((keys) => {
      const categs = removeDuplicates(
        keys.map((ting) => {
          return ting.Category;
        })
      );
      setCategories(categs);
      console.log(categs);
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="poppins">
      {/* HELMET */}
      <Helmet>
        <title>Categories | Shopfolio Shopping Experience</title>
        <meta
          name="description"
          content="Discover the best from handpicked Shopify vendors at Shopfolio. Our curated catalog offers top-quality products and a seamless shopping experience. Support small businesses and independent artisans while enjoying fashion, home goods, accessories, and more. Shop now for the best in quality and style!"
        />
        <meta name="robots" content="index, follow" />
        <meta
          name="keywords"
          content="shopping,Shopify,products,market,pop! market,clothing,toys,home,stationery,technology"
        />
        <meta http-equiv="content-language" content="en-us" />
        <meta name="author" content="Innovative Internet Creations" />
        <link rel="canonical" href="https://www.popmarketonline.com" />
      </Helmet>
      <PageNavigation />
      <div className="body">
        {/* TOP */}
        <div className="page-top">
          <h1 className="no">What are you looking for?</h1>
        </div>
        <Spacer height={10} />
        {/* BODY */}
        <div className="categories-wrap">
          {sortArray(categories, "asc").map((categ, i) => {
            return (
              <div
                key={i}
                className="category-block pointer"
                onClick={() => {
                  navigate(`/products/${categ.replaceAll(" ","-")}`);
                }}
              >
                <p className="no">{categ}</p>
                {/* <MdArrowOutward className="category-arrow" /> */}
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
}
