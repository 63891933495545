import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  limit,
  where,
  startAfter,
} from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAyLa1sxXSdUH3rhJQdcv1VI3qDA3ChP6c",
  authDomain: "shop-hq-feef8.firebaseapp.com",
  projectId: "shop-hq-feef8",
  storageBucket: "shop-hq-feef8.appspot.com",
  messagingSenderId: "724976916743",
  appId: "1:724976916743:web:a5beb00139c7028b00cda2",
  measurementId: "G-71HS91E9LP",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export async function firebase_GetAllKeys(setter) {
  try {
    const querySnapshot = await getDocs(collection(db, "Keys"));
    const keys = querySnapshot.docs.map((doc) => doc.data());
    setter(keys);
  } catch (error) {
    console.error("Error getting keys: ", error);
  }
}
export async function firebase_GetLimitedKeys(setter, limit = 10) {
  try {
    const querySnapshot = await getDocs(query(collection(db, "Keys"), limit));
    const keys = querySnapshot.docs.map((doc) => doc.data());
    setter(keys);
  } catch (error) {
    console.error("Error getting keys: ", error);
  }
}
export async function firebase_GetAllKeysByCategory(
  setter,
  lastDoc,
  setLastDoc,
  category
) {
  console.log("GETTING KEYS");
  const collectionRef = collection(db, "Keys");
  let queryRef = collectionRef;

  if (category) {
    queryRef = query(queryRef, where("Category", "==", category));
  }

  if (lastDoc) {
    queryRef = query(queryRef, startAfter(lastDoc));
  }

  queryRef = query(queryRef, limit(10));

  const querySnapshot = await getDocs(queryRef);
  const keys = querySnapshot.docs.map((doc) => doc.data());

  if (querySnapshot.docs.length > 0) {
    const lastOne = querySnapshot.docs[querySnapshot.docs.length - 1];
    setLastDoc(lastOne);
  }

  setter(keys);
}
export async function shopify_GetAllCollections(
  shopifyURL,
  storefrontAPI,
  setter
) {
  try {
    const response = await fetch(
      `https://${shopifyURL}/api/2024-04/graphql.json`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Shopify-Storefront-Access-Token": storefrontAPI,
        },
        body: JSON.stringify({
          query: `
            {
              collections(first: 10) {
                edges {
                  node {
                    id
                    title
                    image {
                        src
                    }
                  }
                }
              }
            }
          `,
        }),
      }
    );
    const { data } = await response.json();
    setter(data.collections.edges.map((edge) => edge.node));
  } catch (error) {
    console.error("Error fetching collections:", error);
  }
}
export async function shopify_GetCollectionByID(
  shopifyURL,
  storefrontAPI,
  collectionID,
  setter
) {
  try {
    const response = await fetch(
      `https://${shopifyURL}/api/2024-04/graphql.json`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Shopify-Storefront-Access-Token": storefrontAPI,
        },
        body: JSON.stringify({
          query: `
            {
              collection(id: "${collectionID}") {
                id
                title
              }
            }
          `,
        }),
      }
    );
    const { data } = await response.json();
    setter(data.collection);
  } catch (error) {
    console.error("Error fetching collection:", error);
  }
}
export async function shopify_GetAllProducts(
  collectionID,
  storefrontAPI,
  shopifyURL,
  setter
) {
  try {
    const response = await fetch(`https://${shopifyURL}/api/2024-04/graphql`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Shopify-Storefront-Access-Token": storefrontAPI,
      },
      body: JSON.stringify({
        query: `
          query {
            collection(id: "${collectionID}") {
              products(first: 100) {
                edges {
                  node {
                    id
                    title
                    description
                    images(first: 1) {
                      edges {
                        node {
                          src
                        }
                      }
                    }
                    variants(first: 1) {
                      edges {
                        node {
                          priceV2 {
                            amount
                            currencyCode
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `,
      }),
    });

    const { data } = await response.json();

    if (!data || !data.collection || !data.collection.products) {
      throw new Error("Failed to fetch products from collection.");
    }

    const products = data.collection.products.edges.map(({ node }) => ({
      id: node.id,
      title: node.title,
      description: node.description,
      imageSrc:
        node.images.edges.length > 0 ? node.images.edges[0].node.src : null,
      price:
        node.variants.edges.length > 0
          ? node.variants.edges[0].node.priceV2.amount
          : null,
      currencyCode:
        node.variants.edges.length > 0
          ? node.variants.edges[0].node.priceV2.currencyCode
          : null,
    }));

    setter(products);
  } catch (error) {
    console.error(error.message);
  }
}
export async function shopify_GetLimitedProducts(
  collectionID,
  storefrontAPI,
  shopifyURL,
  limit = 20,
  setter
) {
  try {
    const response = await fetch(`https://${shopifyURL}/api/2024-04/graphql`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Shopify-Storefront-Access-Token": storefrontAPI,
      },
      body: JSON.stringify({
        query: `
          query {
            collection(id: "${collectionID}") {
              products(first: ${limit}) {
                edges {
                  node {
                    id
                    title
                    description
                    images(first: 1) {
                      edges {
                        node {
                          src
                        }
                      }
                    }
                    variants(first: 1) {
                      edges {
                        node {
                          priceV2 {
                            amount
                            currencyCode
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `,
      }),
    });

    const { data } = await response.json();

    if (!data || !data.collection || !data.collection.products) {
      throw new Error("Failed to fetch products from collection.");
    }

    const products = data.collection.products.edges.map(({ node }) => ({
      id: node.id,
      title: node.title,
      description: node.description,
      imageSrc:
        node.images.edges.length > 0 ? node.images.edges[0].node.src : null,
      price:
        node.variants.edges.length > 0
          ? node.variants.edges[0].node.priceV2.amount
          : null,
      currencyCode:
        node.variants.edges.length > 0
          ? node.variants.edges[0].node.priceV2.currencyCode
          : null,
    }));

    setter(products);
  } catch (error) {
    console.error(error.message);
  }
}
export async function shopify_GetProductsLimit(
  collectionID,
  storefrontAPI,
  shopifyURL,
  setter,
  limit,
  afterCursor = null
) {
  const newCursor = afterCursor === null ? null : `"${afterCursor}"`
  try {
    const response = await fetch(`https://${shopifyURL}/api/2024-04/graphql`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Shopify-Storefront-Access-Token": storefrontAPI,
      },
      body: JSON.stringify({
        query: `
          query {
            collection(id: "${collectionID}") {
              products(first: ${limit}, after: ${newCursor}) {
                edges {
                  node {
                    id
                    title
                    description
                    images(first: 1) {
                      edges {
                        node {
                          src
                        }
                      }
                    }
                    variants(first: 1) {
                      edges {
                        node {
                          priceV2 {
                            amount
                            currencyCode
                          }
                        }
                      }
                    }
                    
                  }
                }
                pageInfo {
                  hasNextPage
                  endCursor
                }
              }
            }
          }
        `,
      }),
    });

    const { data } = await response.json();

    if (!data || !data.collection || !data.collection.products) {
      throw new Error("Failed to fetch products from collection.");
    }

    const products = data.collection.products.edges.map(({ node }) => ({
      id: node.id,
      title: node.title,
      description: node.description,
      imageSrc:
        node.images.edges.length > 0 ? node.images.edges[0].node.src : null,
      price:
        node.variants.edges.length > 0
          ? node.variants.edges[0].node.priceV2.amount
          : null,
      currencyCode:
        node.variants.edges.length > 0
          ? node.variants.edges[0].node.priceV2.currencyCode
          : null,
    }));
    const thisCursor = data.collection.products.pageInfo.endCursor
    const hasNextPage = data.collection.products.pageInfo.hasNextPage
    setter({ Products: products, AfterCursor: thisCursor, HasNextPage: hasNextPage });
  } catch (error) {
    console.error(error.message);
  }
}
export async function shopify_GetProduct(
  productID,
  storefrontAPI,
  shopifyURL,
  setter
) {
  try {
    const response = await fetch(`https://${shopifyURL}/api/2024-04/graphql`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Shopify-Storefront-Access-Token": storefrontAPI,
      },
      body: JSON.stringify({
        query: `
        query {
          product(id: "${productID}") {
            id
            title
            handle
            description
            images(first: 10) {
              edges {
                node {
                  src
                }
              }
            }
            variants(first: 100) {
              edges {
                node {
                  title
                  quantityAvailable: availableForSale
                  priceV2 {
                    amount
                    currencyCode
                  }
                }
              }
            }
          }
        }
      `,
      }),
    });

    const { data } = await response.json();

    if (!data || !data.product) {
      throw new Error("Failed to fetch product.");
    }

    const product = {
      id: data.product.id,
      title: data.product.title,
      handle: data.product.handle,
      description: data.product.description,
      images:
        data.product.images.edges.length > 0
          ? data.product.images.edges.map((ting) => {
              return ting.node.src;
            })
          : null,
      variants: data.product.variants.edges.map(({ node }) => ({
        title: node.title,
        quantity: node.quantityAvailable,
        price: node.priceV2.amount,
        currencyCode: node.priceV2.currencyCode,
      })),
    };

    setter(product);
  } catch (error) {
    console.error(error.message);
  }
}
