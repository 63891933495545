import React, { useEffect, useState } from "react";
import Navigation from "../UTILITIES/Navigation";
import { FaArrowDown, FaArrowRight, FaCircle } from "react-icons/fa";
import { GoNorthStar } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import Footer from "../UTILITIES/Footer";
import { MdArrowOutward } from "react-icons/md";

import img1 from "../IMAGES/shopify1.jpg";
import img2 from "../IMAGES/shopify2.jpg";
import img3 from "../IMAGES/shopify3.jpg";
import logo from "../IMAGES/shophq-blue.png";
import {
  firebase_GetLimitedKeys,
  shopify_GetAllCollections,
  shopify_GetLimitedProducts,
} from "../FIREBASE/firebase";
import { pickRandomNumber, removeDuplicatesByProperty } from "../Global";
import { Spacer } from "../COMPONENT/Components";
import { Helmet } from "react-helmet";

export default function Home() {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    firebase_GetLimitedKeys((keys) => {
      for (var i in keys) {
        const storefront = keys[i].StorefrontAPI;
        const url = keys[i].URL;
        const name = keys[i].Name
        shopify_GetAllCollections(url, storefront, (collections) => {
          const index = pickRandomNumber(0, collections.length - 1);
          shopify_GetLimitedProducts(
            collections[index].id,
            storefront,
            url,
            2,
            (products) => {
              for (var p in products) {
                const prod = products[p]
                setProducts((prev) =>
                removeDuplicatesByProperty([...prev, {...prod, CollectionID: collections[index].id, Name: name}], "id")
              );
              }
            }
          );
        });
      }
    }, 15);
  }, []);

  return (
    <div className="poppins">
      {/* HELMET */}
      <Helmet>
        <title>Shopfolio | A New Shopping Experience</title>
        <meta
          name="description"
          content="Discover the best from handpicked Shopify vendors at Shopfolio. Our curated catalog offers top-quality products and a seamless shopping experience. Support small businesses and independent artisans while enjoying fashion, home goods, accessories, and more. Shop now for the best in quality and style!"
        />
        <meta name="robots" content="index, follow" />
        <meta
          name="keywords"
          content="shopping,Shopify,products,market,pop! market,clothing,toys,home,stationery,technology"
        />
        <meta http-equiv="content-language" content="en-us" />
        <meta name="author" content="Innovative Internet Creations" />
        <link rel="canonical" href="https://www.popmarketonline.com" />
      </Helmet>
      {/* PANEL 1 */}
      <div className="home-1-main">
        <Navigation />

        {/* <h1 className="home-1-head">POP!<br/> MARKET</h1> */}
        <div className="separate shadow-bg">
          <p className="home-1-caption">
            Handpicked trustworthy vendors ready to sell high quality products.
          </p>
          <div className="home-1-caption-icon-wrap">
            <FaArrowDown className="home-1-caption-icon" />
          </div>
        </div>
      </div>

      {/* PANEL 3 */}
      <div className="home-2-wrap">
        <div
          className="home-2-block pointer"
          onClick={() => {
            navigate("/vendors");
          }}
        >
          <h1>Vendors</h1>
          <MdArrowOutward className="home-2-block-icon" />
        </div>
        <div
          className="home-2-block pointer"
          onClick={() => {
            navigate("/categories");
          }}
        >
          <h1>Categories</h1>
          <MdArrowOutward className="home-2-block-icon" />
        </div>
      </div>

      {/* FEAUTURED */}
      <div>
        <h2 className="no" style={{fontSize: "25px", fontWeight: 400, padding: "0px 20px"}}>Featured Products...</h2>
      </div>
      <div className="home-prods">
        <div className="product-container">
          {products.map((prod, i) => {
            return (
              <div key={i} onClick={() => {
                navigate(`${prod.Name.replaceAll(" ", "-")}/${prod.CollectionID.split("/").pop()}/${prod.id.split("/").pop()}`)
              }} className="pointer">
                <div className="prod-img">
                  <img src={prod.imageSrc !== null ? prod.imageSrc : logo} alt={prod.title} />
                </div>
                <Spacer height={10} />
                <p className="no">{prod.title}</p>
                <Spacer height={6} />
                <p className="no">${parseFloat(prod.price).toFixed(2)}</p>
              </div>
            );
          })}
          
        </div>
      </div>

      {/* PANEL 4 */}
      <div className="home-3-wrap">
        <div className="home-3-top">
          <GoNorthStar size={40} color="white" />
          <h1>A new way of shopping.</h1>
        </div>
        <div className="spacer" />
        <div className="home-3-blocks">
          <div className="home-3-block">
            <h3 className="no">Supporting Small Businesses</h3>
            <p className="no">
              Our partners are exceptional businesses dedicated to offering the
              finest products to our customers. We carefully select vendors who
              not only grasp trends and uphold quality but also provide
              excellent support, ensuring a delightful shopping experience for
              all.
            </p>
          </div>
          <div className="home-3-block">
            <h3 className="no">Shopfolio Exclusives</h3>
            <p className="no">
              Since all our partners are meticulously selected by our team,
              we've gifted each vendor a personalized app branded to their
              unique store. There's no need to worry about logging in or
              creating multiple accounts; one account serves for all vendor apps
              throughout the Shopfolio Network.
            </p>
          </div>
          <div className="home-3-block">
            <h3 className="no">Shopify Pipeline</h3>
            <p className="no">
              Attention, Shopify vendors! Reach out to us immediately to secure
              your spot in our exclusive Shopfolio catalog. You'll also
              receive a brand-new app featuring your products and branding,
              creating your very own shopping ecosystem.{" "}
              <a
                style={{ color: "white" }}
                className="underline"
                onClick={() => {
                  navigate("/contact");
                }}
              >
                Contact Us
              </a>{" "}
              using our vendor request form.
            </p>
          </div>
        </div>
      </div>

      {/* PANEL 5 */}
      <div className="home-4-wrap">
        <img src={img1} />
        <img src={img2} />
        <img src={img3} />
      </div>
      <Footer />
    </div>
  );
}
