export function removeDuplicatesByProperty(arr, prop) {
  return arr.filter(
    (obj, index, self) => index === self.findIndex((t) => t[prop] === obj[prop])
  );
}
export function removeDuplicates(arr) {
  return arr.filter((item, index) => arr.indexOf(item) === index);
}
export function sortArray(arr, order = "asc") {
  if (order === "asc") {
    return arr.slice().sort((a, b) => (a > b ? 1 : -1));
  } else if (order === "desc") {
    return arr.slice().sort((a, b) => (a < b ? 1 : -1));
  } else {
    throw new Error('Invalid order. Use "asc" or "desc".');
  }
}
export function sortObjectsByProperty(arr, prop, order = "asc") {
  if (order === "asc") {
    return arr.slice().sort((a, b) => (a[prop] > b[prop] ? 1 : -1));
  } else if (order === "desc") {
    return arr.slice().sort((a, b) => (a[prop] < b[prop] ? 1 : -1));
  } else {
    throw new Error('Invalid order. Use "asc" or "desc".');
  }
}
export function shuffleArray(array) {
  // Create a copy of the original array to avoid mutating it
  const shuffledArray = array.slice();

  // Perform the Fisher-Yates shuffle algorithm
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }

  return shuffledArray;
} 
export function pickRandomNumber(start, limit) {
  // Calculate the range of numbers (inclusive)
  const range = limit - start + 1;
  // Generate a random number within the range
  const randomNumber = Math.floor(Math.random() * range) + start;
  return randomNumber;
}
