import React, { useEffect, useState } from "react";
import {
  firebase_GetAllKeys,
  shopify_GetAllCollections,
  shopify_GetAllProducts,
} from "../FIREBASE/firebase";
import { removeDuplicates } from "../Global";

export default function Sitemap() {
  const url = "https://shopfolio.us";
  const [vendors, setVendors] = useState([]);
  const [main, setMain] = useState([
    "about",
    "partners",
    "contact",
    "categories",
  ]);
  const [categories, setCategories] = useState([]);
  const [productURLs, setProductsURLs] = useState([]);

  function getFormattedDate() {
    const date = new Date();
    const isoString = date.toISOString();

    // Get the timezone offset
    const offsetMinutes = date.getTimezoneOffset();
    const offsetHours = Math.abs(offsetMinutes / 60);
    const offsetSign = offsetMinutes < 0 ? "+" : "-";
    const offsetString = `${offsetSign}${String(offsetHours).padStart(
      2,
      "0"
    )}:${String(Math.abs(offsetMinutes % 60)).padStart(2, "0")}`;

    // Combine date and offset
    const formattedDate = isoString.replace("Z", offsetString);

    return formattedDate;
  }

  useEffect(() => {
    firebase_GetAllKeys((keys) => {
      for (var k in keys) {
        const thisOne = keys[k];
        // VENDOR
        const vendorName = thisOne.Name.replaceAll(" ", "-");
        setVendors((prev) => removeDuplicates([...prev, vendorName]));
        // CATEGORIES
        const category = thisOne.Category;
        setCategories((prev) => removeDuplicates([...prev, category]));
        // PRODUCTS
        shopify_GetAllCollections(
          thisOne.URL,
          thisOne.StorefrontAPI,
          (collections) => {
            for (var c in collections) {
              const coll = collections[c];
              shopify_GetAllProducts(
                coll.id,
                thisOne.StorefrontAPI,
                thisOne.URL,
                (prods) => {
                  for (var p in prods) {
                    const prod = prods[p];
                    const str = `${thisOne.Name.replaceAll(" ", "-")}/${coll.id
                      .split("/")
                      .pop()}/${prod.id.split("/").pop()}`;
                      setProductsURLs((prev) => removeDuplicates([...prev, str]))
                  }
                }
              );
            }
          }
        );
      }
    });
  }, []);

  return (
    <div>
      <p>
        &lt;?xml version="1.0" encoding="UTF-8"?&gt;
        <br />
        &lt;urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9"
        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
        xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9
        http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd"&gt;
        {/* HERE */}
        <br />
        &nbsp;&nbsp;&lt;url&gt;
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&lt;loc&gt;{url}/&lt;/loc&gt;
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&lt;lastmod&gt;{getFormattedDate()}
        &lt;/lastmod&gt;
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&lt;priority&gt;1.0&lt;/priority&gt;
        <br />
        &nbsp;&nbsp;&lt;/url&gt;
        {main.map((page, i) => {
          return (
            <div key={i}>
              <br />
              &nbsp;&nbsp;&lt;url&gt;
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&lt;loc&gt;{url}/{page}&lt;/loc&gt;
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&lt;lastmod&gt;{getFormattedDate()}
              &lt;/lastmod&gt;
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&lt;priority&gt;1.0&lt;/priority&gt;
              <br />
              &nbsp;&nbsp;&lt;/url&gt;
              <br />
            </div>
          );
        })}
        {vendors.map((vendor, i) => {
          return (
            <div key={i}>
              <br />
              &nbsp;&nbsp;&lt;url&gt;
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&lt;loc&gt;{url}/{vendor}&lt;/loc&gt;
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&lt;lastmod&gt;{getFormattedDate()}
              &lt;/lastmod&gt;
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&lt;priority&gt;1.0&lt;/priority&gt;
              <br />
              &nbsp;&nbsp;&lt;/url&gt;
              <br />
            </div>
          );
        })}
        {categories.map((category, i) => {
          return (
            <div key={i}>
              <br />
              &nbsp;&nbsp;&lt;url&gt;
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&lt;loc&gt;{url}/products/
              {category.replaceAll(" ", "-")}&lt;/loc&gt;
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&lt;lastmod&gt;{getFormattedDate()}
              &lt;/lastmod&gt;
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&lt;priority&gt;1.0&lt;/priority&gt;
              <br />
              &nbsp;&nbsp;&lt;/url&gt;
              <br />
            </div>
          );
        })}
        {productURLs.map((prodURL, i) => {
          return (
            <div key={i}>
              <br />
              &nbsp;&nbsp;&lt;url&gt;
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&lt;loc&gt;{url}/
              {prodURL}&lt;/loc&gt;
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&lt;lastmod&gt;{getFormattedDate()}
              &lt;/lastmod&gt;
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&lt;priority&gt;1.0&lt;/priority&gt;
              <br />
              &nbsp;&nbsp;&lt;/url&gt;
              <br />
            </div>
          );
        })}
        {/* HERE */}
        &lt;/urlset&gt;
      </p>
    </div>
  );
}
