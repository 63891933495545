import React from "react";
import { MdArrowOutward } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import logo from "../IMAGES/shophq-blue.png";
import { Spacer } from "../COMPONENT/Components";

export default function Footer() {
  const navigate = useNavigate();
  return (
    <div className="poppins">
      {/* SCROLL */}
      <div className="home-1-banner">
        <div className="text-container">
          <p>Clothing</p>
          <p>Music Accessories</p>
          <p>Toys</p>
          <p>Beauty Products</p>
          <p>Sports Goods</p>
          <p>Home Decor</p>
          <p>Paintings & Frames</p>
          <p>Bags</p>
          <p>Pet Supplies</p>
          <p>Office</p>
          <p>Arts & Crafts</p>
          <p>Vintage</p>
          <p>Jewelery</p>
          {/* Duplicate the text elements for continuous looping */}
          <p>Clothing</p>
          <p>Music Accessories</p>
          <p>Toys</p>
          <p>Beauty Products</p>
          <p>Sports Goods</p>
          <p>Home Decor</p>
          <p>Paintings & Frames</p>
          <p>Bags</p>
          <p>Pet Supplies</p>
          <p>Office</p>
          <p>Arts & Crafts</p>
          <p>Vintage</p>
          <p>Jewelery</p>
        </div>
      </div>
      <Spacer height={10} />
      <div className="footer footer-section-wrap">
        <div className="footer-section">
          <p className="footer-subject no">/01 Links</p>
          {/* LINKS */}
          <div className="footer-links">
            <div
              className="footer-separate"
              onClick={() => {
                navigate("/vendors");
              }}
            >
              <p className="no footer-link pointer">Vendors</p>
              <div className="footer-arrow">{/* <MdArrowOutward /> */}</div>
            </div>
            <div
              className="footer-separate"
              onClick={() => {
                navigate("/categories");
              }}
            >
              <p className="no footer-link pointer">Categories</p>
              <div className="footer-arrow">{/* <MdArrowOutward /> */}</div>
            </div>
            <div
              className="footer-separate"
              onClick={() => {
                navigate("/about");
              }}
            >
              <p className="no footer-link pointer">About</p>
              <div className="footer-arrow">{/* <MdArrowOutward /> */}</div>
            </div>
            <div
              className="footer-separate"
              onClick={() => {
                navigate("/partners");
              }}
            >
              <p className="no footer-link pointer">Shopfolio Partners</p>
              <div className="footer-arrow">{/* <MdArrowOutward /> */}</div>
            </div>
            <div
              className="footer-separate"
              onClick={() => {
                navigate("/contact");
              }}
            >
              <p className="no footer-link pointer">Contact Us</p>
              <div className="footer-arrow">{/* <MdArrowOutward /> */}</div>
            </div>
          </div>
        </div>
        <div className="footer-section">
          <p className="footer-subject no">/02 Socials</p>
          {/* SOCIAL MEDIA */}
          <div className="footer-links">
            <div
              className="footer-separate"
              onClick={() => {
                window.open(
                  "https://www.instagram.com/shopfolio.us/",
                  "_blank"
                );
              }}
            >
              <p className="no footer-link pointer">Instagram</p>
              <div className="footer-arrow">{/* <MdArrowOutward /> */}</div>
            </div>
            <div className="footer-separate" onClick={() => {
               window.open(
                "https://www.facebook.com/shopfolio.us",
                "_blank"
              );
            }}>
              <p className="no footer-link pointer">Facebook</p>
              <div className="footer-arrow">{/* <MdArrowOutward /> */}</div>
            </div>
            <div className="footer-separate" onClick={() => {}}>
              <p className="no footer-link pointer">LinkedIn</p>
              <div className="footer-arrow">{/* <MdArrowOutward /> */}</div>
            </div>
          </div>
        </div>
        <div className="footer-section">
          <p className="footer-subject no">/03 Concept</p>
          {/* SOCIAL MEDIA */}
          <div className="footer-desc">
            <p className="no">
              Shopfolio is a premier online shopping destination offering a vast
              array of products from a diverse range of vendors across the
              country. Our website and apps provide a seamless browsing
              experience, allowing users to explore curated collections and
              discover unique products from the comfort of their homes. With a
              focus on quality, value, and convenience, Shopfolio is your go-to
              destination for all your shopping needs.
            </p>
          </div>
        </div>
      </div>
      <div
        className="footer-img"
        style={{ display: "flex", justifyContent: "center" }}
        onClick={() => {
          navigate("/");
        }}
      >
        <img src={logo} />
      </div>
      <div className="padding footer-copy">
        <p>
          Copyright &copy; 2024 Innovative Internet Creations. All Rights
          Reserved.
        </p>
      </div>
    </div>
  );
}
