import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  firebase_GetAllKeys,
  shopify_GetAllCollections,
} from "../FIREBASE/firebase";
import PageNavigation from "../UTILITIES/PageNavigation";
import { FaArrowLeftLong } from "react-icons/fa6";
import { Spacer } from "../COMPONENT/Components";
import stock from "../IMAGES/shophq-blue.png";
import Footer from "../UTILITIES/Footer";
import { MdArrowOutward } from "react-icons/md";
import { Helmet } from "react-helmet";

export default function Vendor() {
  const { name } = useParams();
  const navigate = useNavigate();
  const [vendorName, setVendorName] = useState("");
  const [collections, setCollections] = useState([]);

  useEffect(() => {
    const thisVendorName = name.replaceAll("-", " ");
    setVendorName(thisVendorName);
    firebase_GetAllKeys((keys) => {
      const thisOne = keys.find((ting) => ting.Name === thisVendorName);
      shopify_GetAllCollections(
        thisOne.URL,
        thisOne.StorefrontAPI,
        (collections) => {
          console.log(collections);
          setCollections(collections);
        }
      );
    });
  }, []);

  return (
    <div className="poppins">
      {/* HELMET */}
      <Helmet>
        <title>
          {name.replaceAll("-", " ")} | Shopfolio Shopping Experience
        </title>
        <meta
          name="description"
          content="Discover the best from handpicked Shopify vendors at Shopfolio. Our curated catalog offers top-quality products and a seamless shopping experience. Support small businesses and independent artisans while enjoying fashion, home goods, accessories, and more. Shop now for the best in quality and style!"
        />
        <meta name="robots" content="index, follow" />
        <meta
          name="keywords"
          content="shopping,Shopify,products,market,pop! market,clothing,toys,home,stationery,technology"
        />
        <meta http-equiv="content-language" content="en-us" />
        <meta name="author" content="Innovative Internet Creations" />
        <link rel="canonical" href="https://www.popmarketonline.com" />
      </Helmet>
      <PageNavigation />
      {/* BACK */}
      <div
        className="side padding_h"
        onClick={() => {
          navigate("/vendors");
        }}
      >
        <FaArrowLeftLong />
        <p className="no pointer">back</p>
      </div>
      <Spacer height={20} />
      <div className="body">
        {/* BODY */}
        <div className="vendor-top">
          <h1 className="no">{vendorName}</h1>
        </div>
        {/* COLLECTIONS */}
        {/* <h1 className="page-title">Collections</h1> */}
        <div className="vendor-collections">
          {collections
            .filter((ting) => ting.title !== "Home page" && ting.image !== null)
            .map((col, c) => {
              return (
                <div
                  key={c}
                  className="vendor-collection pointer"
                  onClick={() => {
                    const collectionID = col.id.split("/").pop();
                    navigate(`/${name}/${collectionID}`);
                  }}
                >
                  <div style={{ backgroundColor: "#EFF1F7" }}>
                    <img src={col.image !== null ? col.image.src : stock} alt={col.title} />
                  </div>
                  <div className="vendor-collection-title">
                    <p className="no">{col.title}</p>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="vendor-collections">
          {collections
            .filter((ting) => ting.title !== "Home page" && ting.image === null)
            .map((col, c) => {
              return (
                <div
                  key={c}
                  className=" pointer"
                  onClick={() => {
                    const collectionID = col.id.split("/").pop();
                    navigate(`/${name}/${collectionID}`);
                  }}
                >
                  <div className="vendor-collection-bottom side padding">
                    <p className="no">{col.title}</p>
                    <div className="vendor-collection-bottom">
                      {/* <MdArrowOutward className="vendor-collection-icon" /> */}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <Footer />
    </div>
  );
}
