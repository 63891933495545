import React from "react";
import ReactDOM from "react-dom/client";
// import App from "./App";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./STYLES/Fonts.css";
import "./STYLES/Home.css";
import "./STYLES/Vendors.css";
import "./STYLES/Vendor.css";
import "./STYLES/Collection.css";
import "./STYLES/Product.css";
import "./STYLES/Categories.css";
import "./STYLES/Category.css";
import "./STYLES/Footer.css";
import './STYLES/About.css'
import './STYLES/Partners.css'
import './STYLES/Contact.css'
import "./App.css";
import Home from "./PAGES/Home";
import Vendors from "./PAGES/Vendors";
import Vendor from "./PAGES/Vendor";
import Collection from "./PAGES/Collection";
import Product from "./PAGES/Product";
import Categories from "./PAGES/Categories";
import Category from "./PAGES/Category";
import About from "./PAGES/About";
import Partners from "./PAGES/Partners";
import Contact from "./PAGES/Contact";
import Sitemap from "./PAGES/Sitemap";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/vendors",
    element: <Vendors />,
  },
  {
    path: "/:name",
    element: <Vendor />,
  },
  {
    path: "/:name/:collectionID",
    element: <Collection />,
  },
  {
    path: "/:name/:collectionID/:productID",
    element: <Product />,
  },
  {
    path: "/categories",
    element: <Categories />,
  },
  {
    path: "/products/:category",
    element: <Category />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/partners",
    element: <Partners />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/sitemap",
    element: <Sitemap />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
