import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageNavigation from "../UTILITIES/PageNavigation";
import { FaArrowLeftLong } from "react-icons/fa6";
import {
  firebase_GetAllKeysByCategory,
  shopify_GetAllCollections,
  shopify_GetAllProducts,
  shopify_GetProductsLimit,
} from "../FIREBASE/firebase";
import { removeDuplicatesByProperty, shuffleArray } from "../Global";
import { Spacer } from "../COMPONENT/Components";
import stock from "../IMAGES/shophq-blue.png";
import { MdArrowOutward } from "react-icons/md";
import { TbMoodSad2 } from "react-icons/tb";
import Footer from "../UTILITIES/Footer";
import { Helmet } from "react-helmet";

export default function Category() {
  const { category } = useParams();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);
  const [noMore, setNoMore] = useState(false);
  //

  useEffect(() => {
    firebase_GetAllKeysByCategory(
      (keys) => {
        if (keys.length === 0) {
          setNoMore(true);
        }
        for (var i in keys) {
          const key = keys[i];
          shopify_GetAllCollections(
            key.URL,
            key.StorefrontAPI,
            (collections) => {
              for (var c in collections) {
                const coll = collections[c];
                const collID = coll.id;
                shopify_GetProductsLimit(
                  collID,
                  key.StorefrontAPI,
                  key.URL,
                  (products) => {
                    const productsWithMetadata = products.Products.map(
                      (product) => ({
                        ...product,
                        CollectionID: collID.split("/").pop(),
                        Name: key.Name,
                      })
                    );
                    setProducts((prev) =>
                      removeDuplicatesByProperty(
                        [...prev, ...productsWithMetadata],
                        "id"
                      )
                    );
                  },
                  8,
                  null
                );
              }
            }
          );
        }
      },
      lastDoc,
      setLastDoc,
      category.replaceAll("-"," ")
    );
  }, []);

  return (
    <div className="poppins">
      {/* HELMET */}
      <Helmet>
        <title>{category.replaceAll("-"," ")} | Shopfolio Shopping Experience</title>
        <meta
          name="description"
          content="Discover the best from handpicked Shopify vendors at Shopfolio. Our curated catalog offers top-quality products and a seamless shopping experience. Support small businesses and independent artisans while enjoying fashion, home goods, accessories, and more. Shop now for the best in quality and style!"
        />
        <meta name="robots" content="index, follow" />
        <meta
          name="keywords"
          content="shopping,Shopify,products,market,pop! market,clothing,toys,home,stationery,technology"
        />
        <meta http-equiv="content-language" content="en-us" />
        <meta name="author" content="Innovative Internet Creations" />
        <link rel="canonical" href="https://www.popmarketonline.com" />
      </Helmet>
      <PageNavigation />

      <div className="body">
        {/* BACK */}
        <div
          className="side padding_h pointer"
          onClick={() => {
            navigate("/categories");
          }}
        >
          <FaArrowLeftLong />
          <p className="no">categories</p>
        </div>
        <div className="all-prods-top">
          <h1 className="all-prods-title no">{category.replaceAll("-"," ")}</h1>
          <Spacer height={15} />
          <div className="all-prods-wrap">
            {products.map((prod, i) => {
              return (
                <div
                  className="all-prod pointer"
                  key={i}
                  onClick={() => {
                    navigate(
                      `/${prod.Name}/${prod.CollectionID}/${prod.id
                        .split("/")
                        .pop()}`
                    );
                  }}
                >
                  <div style={{ backgroundColor: "#EFF1F7" }}>
                    <img src={prod.imageSrc !== null ? prod.imageSrc : stock} alt={prod.title} />
                  </div>
                  <div className="all-prods-bottom separate">
                    <div className="">
                      <p className="all-prod-title no">{prod.title}</p>
                      <p className="all-prod-price no">
                        ${parseFloat(prod.price).toFixed(2)}
                      </p>
                    </div>
                    <div className="all-prod-icon">
                      {/* <MdArrowOutward /> */}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <Spacer height={30} />
          {!noMore && (
            <div
              style={{
                padding: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => {
                firebase_GetAllKeysByCategory(
                  (keys) => {
                    if (keys.length === 0) {
                      setNoMore(true);
                    }
                    for (var i in keys) {
                      const key = keys[i];
                      shopify_GetAllCollections(
                        key.URL,
                        key.StorefrontAPI,
                        (collections) => {
                          for (var c in collections) {
                            const coll = collections[c];
                            const collID = coll.id;
                            shopify_GetProductsLimit(
                              collID,
                              key.StorefrontAPI,
                              key.URL,
                              (products) => {
                                const productsWithMetadata =
                                  products.Products.map((product) => ({
                                    ...product,
                                    CollectionID: collID.split("/").pop(),
                                    Name: key.Name,
                                  }));
                                setProducts((prev) =>
                                  removeDuplicatesByProperty(
                                    [...prev, ...productsWithMetadata],
                                    "id"
                                  )
                                );
                              },
                              8,
                              null
                            );
                          }
                        }
                      );
                    }
                  },
                  lastDoc,
                  setLastDoc,
                  category.replaceAll("-"," ")
                );
              }}
            >
              <p className="no no_more pointer">See More..</p>
            </div>
          )}
          {noMore && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="side">
                <p className="text_center no_more">No more products</p>
                <TbMoodSad2 size={20} />
              </div>
            </div>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
}
