import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../IMAGES/shophq-white.png";
import blueLogo from "../IMAGES/shophq-blue.png";
import { ImMenu } from "react-icons/im";
import "../STYLES/Navigation.css";
import { HiOutlineXMark } from "react-icons/hi2";
import { MdArrowOutward } from "react-icons/md";

export default function Navigation() {
  const navigate = useNavigate();
  const [showBody, setShowBody] = useState(false);
  return (
    <div className="nav-top">
      <div className="">
        <a
          onClick={() => {
            navigate("/");
          }}
        >
          <img src={logo} width={150} height={150} />
        </a>
      </div>
      <div
        className="burger pointer"
        onClick={() => {
          setShowBody(true);
        }}
      >
        <div className="burger-icon">
          <ImMenu color="white" size={30} />
        </div>
      </div>

      {/* BODY */}
      {showBody && (
        <div className="nav-body">
          <div className="nav-body-top">
            <img src={logo} />
            <div
              className="pointer"
              onClick={() => {
                setShowBody(false);
              }}
            >
              <HiOutlineXMark className="nav-body-x" />
            </div>
          </div>
          <div className="nav-body-mid">
            <div
              className="nav-body-link pointer"
              onClick={() => {
                navigate("/vendors");
                setShowBody(false);
              }}
            >
              <p className="no">Vendors</p>
              {/* <MdArrowOutward className="nav-body-icon" /> */}
            </div>
            <div
              className="nav-body-link pointer"
              onClick={() => {
                navigate("/categories");
                setShowBody(false);
              }}
            >
              <p className="no">Categories</p>
              {/* <MdArrowOutward className="nav-body-icon" /> */}
            </div>
            <div
              className="nav-body-link pointer"
              onClick={() => {
                navigate("/about");
                setShowBody(false);
              }}
            >
              <p className="no">About</p>
              {/* <MdArrowOutward className="nav-body-icon" /> */}
            </div>
            <div
              className="nav-body-link pointer"
              onClick={() => {
                navigate("/partners");
                setShowBody(false);
              }}
            >
              <p className="no">Shopfolio Partners</p>
              {/* <MdArrowOutward className="nav-body-icon" /> */}
            </div>
            <div
              className="nav-body-link pointer"
              onClick={() => {
                navigate("/contact");
                setShowBody(false);
              }}
            >
              <p className="no">Contact Us</p>
              {/* <MdArrowOutward className="nav-body-icon" /> */}
            </div>
          </div>
          <div></div>
          <div className="nav-body-copy">
            <p className="no">
              Copyright &copy; Innovative Internet Creations 2024. All Rights
              Reserved.
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
