import React, { useEffect, useState } from "react";
import PageNavigation from "../UTILITIES/PageNavigation";
import { useNavigate, useParams } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import { MdOutlineArrowOutward } from "react-icons/md";
import {
  firebase_GetAllKeys,
  shopify_GetAllProducts,
  shopify_GetCollectionByID,
  shopify_GetProductsLimit,
} from "../FIREBASE/firebase";
import { Spacer } from "../COMPONENT/Components";
import stock from "../IMAGES/shophq-blue.png";
import Footer from "../UTILITIES/Footer";
import { removeDuplicatesByProperty } from "../Global";
import { TbMoodSad, TbMoodSad2 } from "react-icons/tb";
import { Helmet } from "react-helmet";

export default function Collection() {
  const { name, collectionID } = useParams();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);
  const [noMore, setNoMore] = useState(false)
  const [collection, setCollection] = useState("")

  useEffect(() => {
    firebase_GetAllKeys((keys) => {
      const thisOne = keys.find(
        (ting) => ting.Name === name.replaceAll("-", " ")
      );
      shopify_GetCollectionByID(thisOne.URL, thisOne.StorefrontAPI, `gid://shopify/Collection/${collectionID}`, (thisCollection) => {
        setCollection(thisCollection)
      })
      shopify_GetProductsLimit(
        `gid://shopify/Collection/${collectionID}`,
        thisOne.StorefrontAPI,
        thisOne.URL,
        (theseProducts) => {
          setProducts(theseProducts.Products);
          setCurrentPage(theseProducts.AfterCursor);
          if (!theseProducts.HasNextPage) {
            setNoMore(true)
          }
        },
        20,
        currentPage
      );
      // shopify_GetAllProducts(
      //   `gid://shopify/Collection/${collectionID}`,
      //   thisOne.StorefrontAPI,
      //   thisOne.URL,
      //   (prods) => {
      //     setProducts(prods);
      //   }
      // );
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="poppins">
      {/* HELMET */}
      <Helmet>
        <title>{`${collection.title}`} - {name.replaceAll("-"," ")} | Shopfolio Shopping Experience</title>
        <meta
          name="description"
          content="Discover the best from handpicked Shopify vendors at Shopfolio. Our curated catalog offers top-quality products and a seamless shopping experience. Support small businesses and independent artisans while enjoying fashion, home goods, accessories, and more. Shop now for the best in quality and style!"
        />
        <meta name="robots" content="index, follow" />
        <meta
          name="keywords"
          content="shopping,Shopify,products,market,pop! market,clothing,toys,home,stationery,technology"
        />
        <meta http-equiv="content-language" content="en-us" />
        <meta name="author" content="Innovative Internet Creations" />
        <link rel="canonical" href="https://www.popmarketonline.com" />
      </Helmet>
      <PageNavigation />
      <div className="body">
        {/* BACK */}
        <div
          className="side padding_h"
          onClick={() => {
            navigate(`/${name}`);
          }}
        >
          <FaArrowLeftLong />
          <p className="no pointer">back</p>
        </div>
        <Spacer height={15} />
        {/* BODY */}
        <div className="products">
          {products.map((product, i) => {
            return (
              <div
                key={i}
                className="product-wrap pointer"
                onClick={() => {
                  navigate(
                    `/${name}/${collectionID}/${product.id.split("/").pop()}`
                  );
                }}
              >
                <div className="product-wrap-img">
                  <img
                    src={product.imageSrc !== null ? product.imageSrc : stock}
                    alt={product.title}
                  />
                </div>
                <div className="product-wrap-bottom">
                  <div>
                    <p className="no product-title">{product.title}</p>
                    <Spacer height={5} />
                    <p className="no collection-product-price">
                      ${parseFloat(product.price).toFixed(2)}
                    </p>
                  </div>
                  <div className="product-icon">
                    {/* <MdOutlineArrowOutward /> */}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {!noMore && <div
          onClick={() => {
            firebase_GetAllKeys((keys) => {
              const thisOne = keys.find(
                (ting) => ting.Name === name.replaceAll("-", " ")
              );
              shopify_GetProductsLimit(
                `gid://shopify/Collection/${collectionID}`,
                thisOne.StorefrontAPI,
                thisOne.URL,
                (theseProducts) => {
                  console.log(theseProducts.Products)
                  setProducts((prev) =>
                    removeDuplicatesByProperty(
                      [...prev, ...theseProducts.Products],
                      "id"
                    )
                  );
                  setCurrentPage(theseProducts.AfterCursor);
                  if (!theseProducts.HasNextPage) {
                    setNoMore(true)
                  }
                },
                20,
                currentPage
              );
              // shopify_GetAllProducts(
              //   `gid://shopify/Collection/${collectionID}`,
              //   thisOne.StorefrontAPI,
              //   thisOne.URL,
              //   (prods) => {
              //     setProducts(prods);
              //   }
              // );
            });
          }}
          className="pointer"
        >
          <p style={{ textAlign: "center" }}>See More..</p>
        </div>}
        {noMore && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="side">
                <p className="text_center no_more">No more products</p>
                <TbMoodSad2 size={20} />
              </div>
            </div>
          )}
      </div>

      <Footer />
    </div>
  );
}
