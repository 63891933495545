import React, { useEffect } from "react";
import PageNavigation from "../UTILITIES/PageNavigation";
import Footer from "../UTILITIES/Footer";
import { Helmet } from "react-helmet";

export default function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="poppins">
      {/* HELMET */}
      <Helmet>
        <title>Contact Us | Shopfolio Shopping Experience</title>
        <meta
          name="description"
          content="Discover the best from handpicked Shopify vendors at Shopfolio. Our curated catalog offers top-quality products and a seamless shopping experience. Support small businesses and independent artisans while enjoying fashion, home goods, accessories, and more. Shop now for the best in quality and style!"
        />
        <meta name="robots" content="index, follow" />
        <meta
          name="keywords"
          content="shopping,Shopify,products,market,pop! market,clothing,toys,home,stationery,technology"
        />
        <meta http-equiv="content-language" content="en-us" />
        <meta name="author" content="Innovative Internet Creations" />
        <link rel="canonical" href="https://www.popmarketonline.com" />
      </Helmet>
      <PageNavigation />

      <div className="body contact-wrap">
        <div className="contact-head">
          <h1 className="no">/ Keep in touch!</h1>
          <p>
            The best way to reach out to us is by email. We will make it a
            priority to get back to you with a proper reponse for any questions,
            comments, or concerns.
          </p>
          <a
            href="mailto:bagel@shopfolio.us"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h2>bagel@shopfolio.us</h2>
          </a>
        </div>
      </div>

      <Footer />
    </div>
  );
}
