import React, { useEffect, useState } from "react";
import PageNavigation from "../UTILITIES/PageNavigation";
import { firebase_GetAllKeys } from "../FIREBASE/firebase";
import {
  removeDuplicates,
  removeDuplicatesByProperty,
  sortArray,
  sortObjectsByProperty,
} from "../Global";
import { MdArrowOutward } from "react-icons/md";
import { Spacer } from "../COMPONENT/Components";
import { useNavigate } from "react-router-dom";
import Footer from "../UTILITIES/Footer";
import { Helmet } from "react-helmet";

export default function Vendors() {
  const navigate = useNavigate();
  const [vendors, setVendors] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    firebase_GetAllKeys((keys) => {
      // console.log(keys);
      const filtered = removeDuplicatesByProperty(keys, "AdminAPI");
      const cats = keys.map((ting) => {
        return ting.Category;
      });
      setCategories(removeDuplicates(cats));
      setAllCategories(removeDuplicates(cats));
      setVendors(filtered);
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="poppins">
      {/* HELMET */}
      <Helmet>
        <title>Vendors | Shopfolio Shopping Experience</title>
        <meta
          name="description"
          content="Discover the best from handpicked Shopify vendors at Shopfolio. Our curated catalog offers top-quality products and a seamless shopping experience. Support small businesses and independent artisans while enjoying fashion, home goods, accessories, and more. Shop now for the best in quality and style!"
        />
        <meta name="robots" content="index, follow" />
        <meta
          name="keywords"
          content="shopping,Shopify,products,market,pop! market,clothing,toys,home,stationery,technology"
        />
        <meta http-equiv="content-language" content="en-us" />
        <meta name="author" content="Innovative Internet Creations" />
        <link rel="canonical" href="https://www.popmarketonline.com" />
      </Helmet>
      {/* NAVIGATION */}

      <PageNavigation />
      <div className="body">
        <div className="top-categories">
          {["All", ...sortArray(allCategories, "asc")].map((cat, c) => {
            return (
              <div
                key={c}
                className="top-category pointer"
                onClick={() => {
                  if (cat === "All") {
                    setCategories(allCategories);
                  } else {
                    setCategories([cat]);
                  }
                }}
              >
                <p className="no">{cat}</p>
              </div>
            );
          })}
        </div>
        <div className="padding vendor-blocks">
          {sortArray(categories, "asc").map((cat, c) => {
            return (
              <div key={c} className="vendors">
                <h4 className="no category">{cat}</h4>
                {sortObjectsByProperty(vendors, "Name", "asc")
                  .filter((ting) => ting.Category === cat)
                  .map((vendor, i) => {
                    return (
                      <div
                        className="vendor-wrap pointer"
                        key={i}
                        onClick={() => {
                          navigate(`/${vendor.Name.replaceAll(" ", "-")}`);
                        }}
                      >
                        <p className="no">{vendor.Name}</p>
                        {/* <MdArrowOutward className="vendor-arrow" /> */}
                      </div>
                    );
                  })}
                <Spacer height={15} />
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
}
