import React, { useState } from "react";
import "../STYLES/PageNavigation.css";
import logoBlue from '../IMAGES/shophq-blue.png'
import logo from "../IMAGES/shophq-white.png";
import { useNavigate } from "react-router-dom";
import { TfiMenu } from "react-icons/tfi";
import { HiOutlineXMark } from "react-icons/hi2";
import { MdArrowOutward } from "react-icons/md";

export default function PageNavigation() {
  const navigate = useNavigate();
  const [showBody, setShowBody] = useState(false);

  return (
    <div className="roboto" style={{ height: "80px" }}>
      {/* TOP */}
      <div className="page-nav-top fixed">
        <div
          className="pointer"
          onClick={() => {
            navigate("/");
          }}
        >
          <img src={logoBlue} width={80} height={80} className="nav-logo" />
        </div>
        <div className="page-burger pointer" onClick={() => {
          setShowBody(true)
        }}>
          <TfiMenu />
        </div>
      </div>
      {/* BODY */}
      {/* BODY */}
      {showBody && (
        <div className="nav-body">
          <div className="nav-body-top">
            <img src={logo} />
            <div
              className="pointer"
              onClick={() => {
                setShowBody(false);
              }}
            >
              <HiOutlineXMark className="nav-body-x" />
            </div>
          </div>
          <div className="nav-body-mid">
            <div
              className="nav-body-link pointer"
              onClick={() => {
                navigate("/vendors");
                setShowBody(false);
              }}
            >
              <p className="no">Vendors</p>
              {/* <MdArrowOutward className="nav-body-icon" /> */}
            </div>
            <div
              className="nav-body-link pointer"
              onClick={() => {
                navigate("/categories");
                setShowBody(false);
              }}
            >
              <p className="no">Categories</p>
              {/* <MdArrowOutward className="nav-body-icon" /> */}
            </div>
            <div
              className="nav-body-link pointer"
              onClick={() => {
                navigate("/about");
                setShowBody(false);
              }}
            >
              <p className="no">About</p>
              {/* <MdArrowOutward className="nav-body-icon" /> */}
            </div>
            <div
              className="nav-body-link pointer"
              onClick={() => {
                navigate("/partners");
                setShowBody(false);
              }}
            >
              <p className="no">Shopfolio Partners</p>
              {/* <MdArrowOutward className="nav-body-icon" /> */}
            </div>
            <div
              className="nav-body-link pointer"
              onClick={() => {
                navigate("/contact");
                setShowBody(false);
              }}
            >
              <p className="no">Contact Us</p>
              {/* <MdArrowOutward className="nav-body-icon" /> */}
            </div>
          </div>
          <div></div>
          <div className="nav-body-copy">
            <p className="no">
              Copyright &copy; Innovative Internet Creations 2024. All Rights
              Reserved.
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
